import React, { useState, useEffect } from "react";
import "./page.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import SLoader from "../Components/Loader/SigninLoader";
import CPoints from "./pointsjson";
import ButtonPagenate from "../Components/ButtonPagenate/ButtonPagenate";
// import Error from '../Component/Error'
// import TableDataJson from './tabledatajson'
import { BiGift } from "react-icons/bi";
import { FaRegMoneyBillAlt, FaSuitcase } from "react-icons/fa";
import Currencycode from "../Components/Card/currencycode";
import Error from "../Components/Modal/Error";
import { useSelector } from "react-redux";
import { API, NodeServer_API } from "../server";
import { toast } from "react-toastify";
import { IoChevronDown } from "react-icons/io5";
import { Store } from "../Redux/store";
import { claimsRecord, transactionRecord } from "../Redux/Actions/dataResponse";
import formatDateTime from "../Utils/Datefunction";
import { useTranslation } from "react-i18next";

const Points = ({ changed, respmessage }) => {
  const { t } = useTranslation();
  const { userdata, pointsdata, exchangerate, bankdetails, claimsdata } =
    useSelector((state) => state.datareponse);

  const { paystackWalletBalance } = useSelector((state) => state.paystackdata);

  let users = userdata;
  let RG = users.region_code;

  const [cookies] = useCookies(["Token"]);
  const [pageNumber, setPageNumber] = useState(0);

  const [selected, setSelected] = useState(0);
  const [accountnumber, setAccountnumber] = useState("");

  useEffect(() => {
    let selectedbank = bankdetails?.find((bankdet) => bankdet.ID === selected);

    setAccountnumber(selectedbank?.accountNumber);
  }, [selected, bankdetails]);

  const [transferdetails, setTransferdetails] = useState({
    points: 0,
    recipientCode: "",
  });

  const itemsPerpage = 6; //Number of items to be displayed per page
  const pageCount = Math.ceil(claimsdata?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const objData = {
      points: transferdetails.points,
      recipientCode: transferdetails.recipientCode,
      bank: socialvalue,
      accountnumber: accountnumber,
      amount:
        exchangerate?.data?.amountPerDollar *
        parseInt(transferdetails.points) *
        100,
      users,
      exchangerate,
    };
    // recipientCode: "RCP_mlw3jlnltpdkh3w",

    // Loading
    respmessage({
      loadstatus: 1,
      loadMessage: "Processing Claims...",
      icon: "0",
    });
    changed(1); // Trigger DB fetch

    // if withdrawal point < 2000
    if (
      exchangerate?.data?.amountPerDollar * parseInt(transferdetails.points) >
      2000
    ) {
      // if withdrawal amount is greater than 50,0000
      if (objData.amount > 2000000) {
        // if amount to withdraw is less than available balance; proceed to withdraw
        if (objData.amount < paystackWalletBalance) {
          try {
            var config = {
              method: "POST",
              // url: `${API}/agent/claim`,
              url:
                RG === "NG"
                  ? `${NodeServer_API}/claims/team1`
                  : `${NodeServer_API}/claims/team2`,
              data: objData,
              headers: {
                Authorization: "Bearer " + cookies.Token,
              },
            };

            await axios(config).then((response) => {
              if (response.data.success === true) {
                changed(1); // Trigger DB fetch
                respmessage({
                  loadstatus: 0,
                  loadMessage: "",
                  icon: "0",
                });
                Store.dispatch(
                  transactionRecord(response.data.data.transactionhistory)
                );

                Store.dispatch(claimsRecord(response.data.data.claimsdata));
                toast.success(response.data.message);
              } else {
                respmessage({
                  loadstatus: 0,
                  loadMessage: "",
                  icon: "0",
                });

                toast.error(response.data.message);
              }
            });
          } catch (e) {
            // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr

            if (e.message.includes("401")) {
              // Handle Over Claim
              respmessage({
                loadstatus: 2,
                loadMessage: "Session Expired!",
                icon: "2",
              });
            } else {
              respmessage({
                loadstatus: 0,
                loadMessage: "",
                icon: "0",
              });

              toast.error("Error:", e);
            }
          }
        } else {
          respmessage({
            loadstatus: 0,
            loadMessage: "",
            icon: "0",
          });

          toast.error(
            "Ooops! The Maximum Withdrawal at a time is ₦20000 , try something lesser!"
          );
        }
      } else {
        respmessage({
          loadstatus: 0,
          loadMessage: "",
          icon: "0",
        });

        toast.error(
          "Ooops! Unable to dispense that amount, try something lesser!"
        );
      }
    } else {
      toast.error(`OOps! You cannot claim less than ${currencyCode} 2000`);

      respmessage({
        loadstatus: 0,
        loadMessage: "",
        icon: "0",
      });
    }
  };

  const onChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      // Check if the field contains number only
      if (event.target.value.length !== 0) {
        setTransferdetails({ ...transferdetails, points: event.target.value });
      } else {
        setTransferdetails({ ...transferdetails, points: "" });
      }
    }
  };

  let currencyCode = Currencycode[users.region_code];

  const [socialvalue, setSocialvalue] = useState("");
  const [social, setSocial] = useState(false);

  const handleSocialFocus = () => {
    setSocial(!social);
  };

  const handleSocialClick = (e, incoming) => {
    // console.log(incoming);
    setTransferdetails({
      ...transferdetails,
      recipientCode: incoming.recipientcode,
    });

    setSelected(incoming.id);

    setSocialvalue(incoming.bankname);
    setSocial(!social);
  };

  return (
    <div className="bnw-claim-container">
      <div className="point-redemption-container">
        <div className="redeem-point-row-1">
          <span>
            <FaRegMoneyBillAlt />
          </span>
          <span>{t("redeemypoint")}</span>
        </div>

        {/* <div>
          Current exchange rate -{" "}
          <b>
            {" "}
            {currencyCode} {exchangerate?.data?.amountPerDollar}{" "}
            {currencyCode} / $
          </b>
        </div> */}

        <div>
          <b>NB:</b> {t("claimsnotabene")}
          {/* ( {currencyCode}{" "}
          {exchangerate?.data?.amountPerDollar} / $ ) */}
        </div>

        <div>
          <b>NB:</b> {t("notabeneclaims")}
        </div>

        <div className="w-full text-[13px] font-[500]">
          <form
            onSubmit={handleClick}
            className="w-[80%] flex flex-col gap-1 min-w-[280px]"
          >
            <div className="numberfield-container ">
              <div>
                <BiGift />
              </div>
              <input
                required
                onChange={onChange}
                disabled={pointsdata?.available <= 0 ? true : false}
                value={transferdetails.points}
                type="text"
                placeholder={t("pointstowithdraw")}
                className="numberfield"
              />
            </div>

            <div className="w-full relative">
              <input
                required
                readOnly={true}
                value={socialvalue}
                onClick={pointsdata?.available > 0 && handleSocialFocus}
                placeholder={t("selectaccount")}
                className="input_field w-full outline-none p-[10px] "
              />
              <IoChevronDown
                id="chevron-down"
                onClick={pointsdata?.available > 0 && handleSocialFocus}
              />
              {social && (
                <div
                  onMouseLeave={handleSocialFocus}
                  className="w-full absolute py-1 bg-[white] flex flex-col justify-start max-h-[150px] z-10 top-[38px] overflow-y-scroll border"
                >
                  {bankdetails?.map((item, index) => (
                    <span
                      onClick={(e) =>
                        handleSocialClick(e, {
                          id: item.ID,
                          recipientcode: item.recipientCode,
                          bankname: item.bankName,
                        })
                      }
                      key={index}
                      className="w-full p-2 hover:bg-[#ccc4] border-b"
                    >
                      {item.bankName}
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div className="w-full">
              <input
                disabled
                className="input_field w-full p-2 border"
                placeholder={t("accountnumber")}
                value={accountnumber}
                onChange={() => setTransferdetails({ ...transferdetails })}
              />
            </div>

            <div className="w-full flex gap-2 mt-3">
              <span>{t("pointstoremove")}</span>
              <span>
                @{currencyCode} ∼{" "}
                {exchangerate?.data.amountPerDollar *
                  parseInt(transferdetails.points)}
              </span>
            </div>

            <div className="w-full flex relative text-[#fff] gap-4 items-center justify-center overflow-hidden">
              <FaSuitcase
                color="white"
                className="absolute left-[25%]"
                size={16}
              />
              <input
                disabled={pointsdata?.available <= 0 ? true : false}
                type="submit"
                className={`p-2 text-[14px] w-full h-full ${
                  pointsdata?.available <= 0
                    ? "bg-[#888]"
                    : "bg-[#e50000] cursor-pointer"
                } font-[600] rounded-[5px]`}
                value={t("cashoutpoints")}
              />
            </div>
          </form>
        </div>

        <div></div>
      </div>

      <table>
        <thead>
          <tr>
            <th scope="col">SN</th>
            <th scope="col">{t("points")}</th>
            <th scope="col">₦ {t("value@claim")}</th>
            <th scope="col">{t("status")}</th>
            <th scope="col">{t("daterequested")}</th>
          </tr>
        </thead>

        <tbody>
          {claimsdata
            .slice(pagesVisited, pagesVisited + itemsPerpage)
            .map((items, index) => (
              <tr key={index}>
                <td data-label="S/N">{index + 1}</td>
                <td data-label={t("status")}>{items.points}</td>
                <td data-label={t("daterequested")}>
                  {items.pointsEquivalent}
                </td>
                <td
                  id={
                    items.Status === 1 ? "row-appr-label" : "row-notappr-label"
                  }
                  data-label={t("status")}
                >
                  {items.Status === 1 ? "Approved" : "Not Yet Treated"}
                </td>
                <td data-label={t("daterequested")}>
                  {formatDateTime(items.UpdatedAt)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="bnw-table-pgnate-contain">
        {/* <button id='addbitn' onClick={handleAddButtn}>Add</button> */}
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Points;
