const Currencycode = {
    "BJ" : "XOF",
    "BF" : "XOF",
    "GH" : "GHC",
    "GN" : "XOF",
    "CI" : "CFA",
    "LR" : "LYD",
    "ML" : "XOF",
    "MR" : "MRU",
    "NE" : "XOF",
    "NG" : "NGN",
    "SN" : "XOF",
    "SL" : "SLE",
    "TG" : "XOF",
    "CM" : "XAF",
    "GB" : "XAF",
    "GM" : "GMD"
}
export default Currencycode
