const RegionsJson = [
    {
        ID: 1,
        name: 'Mali',
        value: 'ML'
    },
    {
        ID: 2,
        name: 'Gabon',
        value: 'GB'
    },
    {
        ID: 3,
        name: 'Côte d Ivoire',
        value: 'CI'
    },
    {
        ID: 4,
        name: 'Senegal',
        value: 'SN'
    },
    {
        ID: 5,
        name: 'Mauritania',
        value: 'MR'
    },
    {
        ID: 6,
        name: 'Benin',
        value: 'BJ'
    },
    {
        ID: 7,
        name: 'Togo',
        value: 'TG'
    },
    {
        ID: 8,
        name: 'Niger',
        value: 'NE'
    },
    {
        ID: 9,
        name: 'Liberia',
        value: 'LR'
    },
    {
        ID: 10,
        name: 'Cameroon',
        value: 'CM'
    },
    {
        ID: 11,
        name: 'Burkina Faso',
        value: 'BF'
    },
    {
        ID: 12,
        name: 'Ghana',
        value: 'GH'
    },
    {
        ID: 13,
        name: 'Nigeria',
        value: 'NG'
    }
]

export default RegionsJson