import { create } from "@mui/material/styles/createTransitions";
import { createAction, createReducer } from "@reduxjs/toolkit";

const Userdata = createAction("Userdata");
const PaystackBanks = createAction("PaystackBanks");
const SeerbitsProvider = createAction("SeerbitsProvider");
const ClaimsRecord = createAction("ClaimsRecord");
const TransactionRecord = createAction("TransactionRecord");
const ExchangeRateData = createAction("ExchangeRateData");
const BankDetailsdata = createAction("BankDetailsdata");
const Pointsdata = createAction("Pointsdata");

const initialState = {
  userdata: {},
  exchangerate: {},
  bankdetails: [],
  pointsdata: {},
  paystackbankdata: [],
  seerbitprovider: {},
  transactionhistorydata: [],
  claimsdata: [],
};

export const responseReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(Userdata, (state, action) => {
      state.userdata = action.payload;
    })

    .addCase(ExchangeRateData, (state, action) => {
      state.exchangerate = action.payload;
    })

    .addCase(Pointsdata, (state, action) => {
      state.pointsdata = action.payload;
    })

    .addCase(BankDetailsdata, (state, action) => {
      state.bankdetails = action.payload;
    })

    .addCase(PaystackBanks, (state, action) => {
      state.paystackbankdata = action.payload;
    })

    .addCase(SeerbitsProvider, (state, action) => {
      state.seerbitprovider = action.payload;
    })

    .addCase(ClaimsRecord, (state, action) => {
      state.claimsdata = action.payload;
    })

    .addCase(TransactionRecord, (state, action) => {
      state.transactionhistorydata = action.payload;
    });
});
