export const getCurrentTab = (currenttab) => ({
  type: "CurrentTab",
  payload: currenttab,
});

export const getPagestatus = (pagestatus) => ({
  type: "PageStatus",
  payload: pagestatus,
});

export const captureSidebarClicks = (sidebarclicks) => ({
  type: "Side_Bar_Click",
  payload: sidebarclicks,
});

export const claimsTabstatus = (claimsbarststus) => ({
  type: "Claims_Bar_Status",
  payload: claimsbarststus,
});
