import { createAction, createReducer } from "@reduxjs/toolkit";

const CurrentTab = createAction("CurrentTab");
const PageStatus = createAction("PageStatus");
const Side_Bar_Click = createAction("Side_Bar_Click");
const Claims_Bar_Status = createAction("Claims_Bar_Status");

const initialState = {
  currenttab: 1,
  sidebartab: "Main",
  claimstabstatus: 0,
};

export const buttonclickReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CurrentTab, (state, action) => {
      state.currenttab = action.payload;
    })

    .addCase(PageStatus, (state, action) => {
      state.currenttab = action.payload;
    })

    .addCase(Side_Bar_Click, (state, action) => {
      state.sidebartab = action.payload;
    })

    .addCase(Claims_Bar_Status, (state, action) => {
      state.claimstabstatus = action.payload;
    });
});
