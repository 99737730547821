import React, { useState, useEffect } from "react";
import "./page.css";
// import {useLocation} from 'react-router-dom';
import Body from "./body";
import axios from "axios";
import { useCookies } from "react-cookie";
import Promo from "./Promo";
import Loginpg from "./Login";
import SLoader from "../Components/Loader/SigninLoader";
import TopSidebar from "../Layouts/Sidebar/TopSidebar";
import Sidebar from "../Layouts/Sidebar/Sidebar";
import Error from "../Components/Modal/Error";
import Points from "./points";
import Profile from "./Profile";
import { Store } from "../Redux/store";
import {
  claimsRecord,
  getBankDetails,
  getExchangeRate,
  getPaystackBanks,
  getPoints,
  getSeerbitsBanks,
  getUserData,
} from "../Redux/Actions/dataResponse";
import { API, Paystack_url } from "../server";
import { useSelector } from "react-redux";
import {
  captureSidebarClicks,
  claimsTabstatus,
} from "../Redux/Actions/buttonclicks";
import { paystackWalletBalance } from "../Redux/Actions/paystack";

let setpaystackbanks = [];

const Dashboard = ({ currentpage }) => {
  let paystack_API =
    "https://api.paystack.co/bank?country=nigeria&use_cursor=true&perPage=100";

  const [params, setParams] = useState("");

  const [changedstatus, setChangedstatus] = useState(0);
  const [cookies] = useCookies(["Token"]);
  // const location = useLocation();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(true);
  // const [updateregistration, setUpdateregistration] = useState (true)
  const [treatConfirm, setTreatConfirm] = useState(false);
  const [expiration, setExpiration] = useState(false);
  const [pagestatus, setPagestatus] = useState("");

  const [loadoption, setLoadoption] = useState(1);

  const [treats, setTreats] = useState({
    cids: 0,
    appClm: false,
    treatClm: false,
  });

  const [successful, setSuccessful] = useState({
    statuscode: 0,
    message: "",
    type: "",
  });

  const [responseMessage, setResponseMessage] = useState({
    loadStatus: 0,
    loadmessage: "",
    icon: "",
  });

  const { sidebartab } = useSelector((state) => state.buttonclicks);

  //user1, admin
  useEffect(() => {
    Makerolecall();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    currentpage(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedstatus]);

  useEffect(() => {
    handleClicked(sidebartab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebartab]);

  useEffect(() => {
    FetchAvailableBanks();
    FetchPaystackBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaystackbanks(`${paystack_API}${params}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const FetchPaystackBalance = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/balance`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PS_PUBLIC_LIVE_KEY,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data);
        if (response.data.status === true) {
          Store.dispatch(paystackWalletBalance(response.data.data[0].balance));
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const FetchAvailableBanks = async () => {
    try {
      var config = {
        method: "GET",
        url: `${API}/agent/paymentMetadata`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      console.log(config);

      await axios(config).then((response) => {
        console.log(response);
        if (response.data.success === true) {
          // if user is logging in for the first time or hasnt updated his records
          Store.dispatch(getSeerbitsBanks(response.data.data));
        }
      });
    } catch (e) {
      // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr
      if (e.message.includes("401")) {
        setLoading(false);
      }
    }
  };

  const getPaystackbanks = async (urlendpoint) => {
    try {
      let config = {
        method: "GET",
        url: urlendpoint,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + process.env.REACT_APP_PS_PUBLIC_LIVE_KEY,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.status === true) {
            setpaystackbanks.push(...response.data.data);

            if (response.data.meta.next !== null) {
              const nextUrl = `&next=${response.data.meta.next}`;
              setParams(nextUrl);
            } else {
              // console.log("done");
              // console.log(setpaystackbanks);
              Store.dispatch(getPaystackBanks(setpaystackbanks));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const Makerolecall = async () => {
    // console.log(`Token: ${cookies.Token}`)
    try {
      var config = {
        method: "GET",
        url: `${API}/agent/home`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === false) {
          // if user is logging in for the first time or hasnt updated his records

          Store.dispatch(getUserData(response.data.data.user));

          Store.dispatch(getBankDetails(response.data.data.bankDetails));

          Store.dispatch(getExchangeRate(response.data.data.exchange_rate));

          Store.dispatch(getPoints(response.data.data.points));

          Store.dispatch(claimsRecord(response.data.data.claims));
          if (
            response.data.data.user.pcc === "" ||
            response.data.data.bankDetails.length === 0
          ) {
            setLoading(false);
            Store.dispatch(captureSidebarClicks("Profile"));
            setPage(7);
            setPagestatus("fresh load");
          } else {
            setLoading(false);
          }
        }
      });
    } catch (e) {
      setLoading(false);
      // setExpiration(true)
      if (e.response.status === 401) {
        handleNotice({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        handleNotice({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const handleClicked = (props) => {
    if (props === "Main") {
      setPage(1);
    }

    if (props === "Points") {
      setPage(2);
      Store.dispatch(claimsTabstatus(1));
    }

    if (props === "Profile") {
      setPage(7);
      Store.dispatch(claimsTabstatus(2));
      setPagestatus("not fresh load");
    }

    if (props === "Promo") {
      setPage(3);
    }
  };

  const menubarClick = () => {
    setMenu(!menu);
  };

  const TreatedClick = (incoming) => {
    setTreatConfirm(!treatConfirm);
    setTreats({
      cids: incoming.cid,
      appClm: incoming.aclm,
      treatClm: incoming.tclm,
    });
    // console.log(`Adeyemi: ${JSON.stringify(incoming)}`)
  };

  const handleState = (stateStatus) => {
    setChangedstatus(stateStatus);
    // console.log('we have chaned: ', stateStatus)
  };

  const handleClose = () => {
    setLoadoption(0);
    setResponseMessage({ ...responseMessage, loadStatus: 0 });
  };

  const handleProfileSuccess = (incomingObject) => {
    // console.log(incomingObject)
    setResponseMessage({
      ...responseMessage,
      loadStatus: incomingObject.loadstatus,
      loadmessage: incomingObject.loadMessage,
      responseicon: incomingObject.icon,
    });
  };

  const handleNotice = (props) => {
    setSuccessful({ successful, ...props });
  };

  return (
    <>
      {loading ? (
        <SLoader loggedin={1} type="bars" text="Fetching Data" />
      ) : (
        <div className="bnwd-container">
          <TopSidebar menuClicked={menubarClick} />
          <div className="bnw-botm-container">
            <Sidebar
              changeWid={menubarClick}
              pageStatus={pagestatus}
              changeWidth={menu}
            />
            {page === 1 ? (
              <Body />
            ) : page === 2 ? (
              <Points
                // setreat={TreatedClick}
                changed={handleState}
                respmessage={handleProfileSuccess}
              />
            ) : page === 3 ? (
              <Promo />
            ) : page === 7 ? (
              <Profile respmessage={handleProfileSuccess} />
            ) : (
              <Loginpg />
            )}
          </div>

          {expiration && (
            <Error
              message={"Session Expired Re-Login"}
              isTimeout={true}
              icon={"3"}
            />
          )}

          {responseMessage.loadStatus === 1 ? (
            <SLoader
              loggedin={2}
              type="spin"
              text={responseMessage.loadmessage}
            />
          ) : responseMessage.loadStatus === 2 ? (
            <Error
              closeOverlay={handleClose}
              message={responseMessage.loadmessage}
              isTimeout={false}
              icon={responseMessage.responseicon}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;
