import React, { useState, useEffect } from "react";
import Claimcards from "../Components/Card/Claimcards";
import "./page.css";
import { IoIosNotificationsOutline } from "react-icons/io";
import { BsPencil } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  captureSidebarClicks,
  claimsTabstatus,
} from "../Redux/Actions/buttonclicks";
import { Store } from "../Redux/store";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline, IoWarningOutline } from "react-icons/io5";

const Body = () => {
  const [seewarning, setSeewarning] = useState(true);
  const { paystackWalletBalance } = useSelector((state) => state.paystackdata);

  useEffect(() => {
    paystackWalletBalance < 500000 ? setSeewarning(true) : setSeewarning(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paystackWalletBalance]);

  const { userdata, pointsdata, exchangerate } = useSelector(
    (state) => state.datareponse
  );
  Store.dispatch(claimsTabstatus(0));
  const { t } = useTranslation();

  let totalC =
    pointsdata?.approvedClaims +
    pointsdata?.available +
    pointsdata?.pendingClaims +
    pointsdata?.cancelledClaims;

  // let unapprodedClaims = claims.filter((filtereditems) => (
  //     filtereditems.status = 0
  // ))

  // let cumClaimedPoint = 0
  // let cumPoint = unapprodedClaims.map((filterdObj) => (
  //     cumClaimedPoint + filterdObj.points
  // ))

  // console.log('Cum Claimed Point:', cumPoint)

  useEffect(() => {
    SetIncoming();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetIncoming = () => {};

  const handleClaimsnow = () => {
    Store.dispatch(captureSidebarClicks("Points"));
    // Store.dispatch(claimsTabstatus(1))
  };

  const handleProfilenow = () => {
    Store.dispatch(captureSidebarClicks("Profile"));
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-[80%] flex items-center justify-center">
        {seewarning && (
          <div className="w-fit bg-[orange] text-center flex items-center justify-center gap-2 text-[white] p-2 rounded-[10px] relative">
            <IoWarningOutline size={28} color="white" />
            <span className="text-[13px]">
              <b>{t("paystackprompt")}</b>
            </span>

            <IoCloseCircleOutline
              size={28}
              color="white"
              className="cursor-pointer"
            />
          </div>
        )}
      </div>

      <div className="bnw-body-container">
        <div className="bnw-body-left">
          <div className="bnw-body-left-top">
            {/* <Claimcards
            exchrate={exchangerate?.data?.amountPerDollar}
            backcolor="#FFCBCB"
            value={pointsdata?.disputed}
            countryCode={userdata?.region_code}
            maximum="20000"
            gradient="#FF8855"
            piebackcolor="#FF8E8E"
            text="Invalid Points"
          /> */}

            <Tooltip
              TransitionComponent={Zoom}
              title="Balance Left in Central wallet"
              followCursor
            >
              <Claimcards
                exchrate={exchangerate?.data?.amountPerDollar}
                text={t("accruedpoints")}
                backcolor="#FFF0E8"
                countryCode={userdata?.region_code}
                value={pointsdata?.pending}
                maximum="20000"
                gradient="#FF8855"
                piebackcolor="#FFC8B0"
              />
            </Tooltip>

            <Tooltip
              TransitionComponent={Zoom}
              title="Flown Tickets Points"
              followCursor
            >
              <Claimcards
                exchrate={exchangerate?.data?.amountPerDollar}
                backcolor="#FEFFE3"
                value={pointsdata?.available}
                countryCode={userdata?.region_code}
                maximum="20000"
                gradient="#C0CE22"
                piebackcolor="#F9FFB4"
                text={t("flownpoints")}
              />
            </Tooltip>

            <Tooltip
              TransitionComponent={Zoom}
              title="Total Year to Date (YTD) Redemption Points"
              followCursor
            >
              <Claimcards
                exchrate={exchangerate?.data?.amountPerDollar}
                backcolor="#F2FFE8"
                value={pointsdata?.approvedClaims}
                countryCode={userdata?.region_code}
                maximum="20000"
                gradient="#44CE22"
                piebackcolor="#C0FEB1"
                text={t("totalredemption")}
              />
            </Tooltip>

            {/* <Claimcards
            exchrate={exchangerate?.data?.amountPerDollar}
            backcolor="#f6f8ff"
            text="Total Points"
            value={totalC}
            countryCode={userdata?.region_code}
            maximum="20000"
            gradient="#fffff"
            piebackcolor="#a2b1ff"
          /> */}
          </div>
        </div>
        <div className="bnw-body-right-container">
          <div className="drcont-dp-container">
            <span>
              <BsPencil id="dash-icon" onClick={handleProfilenow} />
            </span>
            <div className="bnw-profileeImg-container">
              <div id="drcont-dp-container-img" />
            </div>
            <span>
              <IoIosNotificationsOutline id="dash-icon" />
            </span>
          </div>
          <span id="profile-name">
            {userdata?.first_name +
              " " +
              (userdata?.last_name === "None" ? "" : userdata?.last_name)}
          </span>
          <span id="profile-email">{userdata?.email}</span>
          <span id="profile-email">{userdata?.phone}</span>
          <div id="profile-id-contain">
            <span id="profile-id">{t("incentivecode")}: </span>
            <span id="profile-id">{userdata?.promo_code}</span>
          </div>
          <div id="profile-id-contain">
            <span id="profile-id">{t("countrycode")}: </span>
            <span id="profile-id">{userdata?.region_code}</span>
          </div>

          <div id="claim-now-buttn">
            <button onClick={handleClaimsnow}>{t("claimnow")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
