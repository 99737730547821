import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import { FaGlobeAfrica, FaBuilding } from "react-icons/fa";
import { BsPersonCircle } from "react-icons/bs";
import { RiDashboardFill } from "react-icons/ri";
import { GiCash } from "react-icons/gi";
import { RiHandCoinLine } from "react-icons/ri";
import { MdTune } from "react-icons/md";
import { RiLogoutCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/store";
import { captureSidebarClicks } from "../../Redux/Actions/buttonclicks";
import { useTranslation } from "react-i18next";

const Sidebar = ({ pageStatus, changeWidth, role, activeC, changeWid }) => {
  const { currenttab, claimstabstatus } = useSelector(
    (state) => state.buttonclicks
  );

  const { t } = useTranslation();

  const { userdata, bankdetails } = useSelector((state) => state.datareponse);

  useEffect(() => {
    if (userdata.pcc === "" || bankdetails.length === 0) {
      setDeactivate(false);
    } else {
      setDeactivate(true);
    }
  }, [bankdetails.length, userdata.pcc]);

  const initialState = [
    { value: "Main", selected: false },
    { value: "Profile", selected: true },
    { value: "Points", selected: true },
    { value: "Promo", selected: true },
    { value: "Logout", selected: true },
  ];

  const [tabs, setTabs] = useState(initialState);

  useEffect(() => {
    if (claimstabstatus === 1) {
      myclickedStatus();
    }

    if (claimstabstatus === 2) {
      myclickedProfile();
    }

    if (currenttab === 7 && pageStatus === "fresh load") {
      handleIncompleteProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimstabstatus]);

  const [deactivate, setDeactivate] = useState(true);

  const iconsObjt = {
    1: <RiDashboardFill id="bnw-sbc-mainicon" />,
    2: <BsPersonCircle id="bnw-sbc-mainicon" />,
    3: <RiHandCoinLine id="bnw-sbc-mainicon" />,
    4: <FaBuilding id="bnw-sbc-mainicon" />,
    5: <MdTune id="bnw-sbc-mainicon" />,
    6: <RiLogoutCircleFill id="bnw-sbc-mainicon" />,
    7: <GiCash id="bnw-sbc-mainicon" />,
    8: <FaGlobeAfrica id="bnw-sbc-mainicon" />,
  };

  const myclickedStatus = () => {
    Store.dispatch(captureSidebarClicks("Points"));

    setTabs((prevTabs) =>
      prevTabs.map((prevTab) =>
        prevTab.value === "Points"
          ? { ...prevTab, selected: false }
          : { ...prevTab, selected: true }
      )
    );
  };

  const myclickedProfile = () => {
    Store.dispatch(captureSidebarClicks("Profile"));

    setTabs((prevTabs) =>
      prevTabs.map((prevTab) =>
        prevTab.value === "Profile"
          ? { ...prevTab, selected: false }
          : { ...prevTab, selected: true }
      )
    );
  };

  const handleIncompleteProfile = () => {
    Store.dispatch(captureSidebarClicks("Profile"));

    setTabs((prevTabs) =>
      prevTabs.map((prevTab) =>
        prevTab.value === "Profile"
          ? { ...prevTab, selected: false }
          : { ...prevTab, selected: true }
      )
    );
  };

  const handleDummyClick = () => {
    // console.log("clicked");
  };

  const actives = "10k+";

  const handleButClick = (e) => {
    changeWid();
    if (e.target.id !== "Logout") {
      Store.dispatch(captureSidebarClicks(e.target.id));
    } else {
      // Call Logout Function
    }

    setTabs((prevTabs) =>
      prevTabs.map((prevTab) =>
        prevTab.value === e.target.id
          ? { ...prevTab, selected: false }
          : { ...prevTab, selected: true }
      )
    );
  };

  return (
    <div
      className={
        changeWidth ? "bnw-sidebar-container" : "bnw-sidebar-container-clicked"
      }
    >
      <ul>
        <li
          className={
            tabs.find((currentmenu) => currentmenu.value === "Main").selected
              ? "bnw-sidbar-container"
              : "active"
          }
          onClick={deactivate ? handleButClick : handleDummyClick}
        >
          <Link
            to="/dashboard"
            className="a"
            id="Main"
            onClick={deactivate ? handleButClick : handleDummyClick}
          >
            <span
              id="Main"
              onClick={deactivate ? handleButClick : handleDummyClick}
            >
              {iconsObjt[1]}
            </span>
            <span
              id="Main"
              onClick={deactivate ? handleButClick : handleDummyClick}
            >
              {t("mainpage")}
            </span>
          </Link>
        </li>

        <li
          className={
            tabs.find((currentmenu) => currentmenu.value === "Profile").selected
              ? "bnw-sidbar-container"
              : "active"
          }
          onClick={deactivate ? handleButClick : handleDummyClick}
        >
          <Link to="" className="a" id="Profile" onClick={handleButClick}>
            <span id="Profile" onClick={handleButClick}>
              {iconsObjt[2]}
            </span>
            <span id="Profile" onClick={handleButClick}>
              {t("profil")}
            </span>
          </Link>
        </li>

        <li
          className={
            tabs.find((currentmenu) => currentmenu.value === "Points").selected
              ? "bnw-sidbar-container"
              : "active"
          }
          onClick={deactivate ? handleButClick : handleDummyClick}
        >
          <Link
            to=""
            className="a"
            id="Points"
            onClick={deactivate ? handleButClick : handleDummyClick}
          >
            <span
              id="Points"
              onClick={deactivate ? handleButClick : handleDummyClick}
            >
              {iconsObjt[3]}
            </span>
            <span
              id="Points"
              onClick={deactivate ? handleButClick : handleDummyClick}
            >
              {t("claims")}
            </span>
          </Link>
        </li>

        {/* <li  className={promo ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Promo" onClick={handleButClick}>
                    <span id="Promo" onClick={handleButClick}>{iconsObjt[7]}</span>
                    <span id="Promo" onClick={handleButClick}>Promo</span>
                </Link>
            </li> */}

        <li
          className={
            tabs.find((currentmenu) => currentmenu.value === "Logout").selected
              ? "bnw-sidbar-container"
              : "active"
          }
          id="Logout"
          onClick={handleButClick}
        >
          <Link to="/" className="a" id="Logout" onClick={handleButClick}>
            <span id="Logout" onClick={handleButClick}>
              {iconsObjt[6]}
            </span>
            <span id="Logout" onClick={handleButClick}>
              {t("logout")}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
